import { useEffect } from 'react';

import { Autocomplete, createFilterOptions, TextField } from '@mui/material';
import { useFormikContext } from 'formik';

import eventHandler from '../utils/eventHandler';
import sortOptions from '../utils/sortOptions';

const DynamicSelect = (props) => {
  const {
    id, name, label, options, sort, after, afterload, change, setFields, setBodyFields, pageType,
  } = props;
  const { setFieldValue, values } = useFormikContext();
  const sortedOptions = sortOptions(options, sort);

  useEffect(() => {
    if (afterload && values[name]) {
      eventHandler({
        name, event: afterload, pageType, values, setFields, setBodyFields, setFieldValue,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [afterload]);

  const handleChange = async (_, value) => {
    setFieldValue(id, value);

    if (after) {
      await eventHandler({
        id, name, value, event: after, pageType, values, setFields, setBodyFields, setFieldValue,
      });
    }

    if (change) {
      await eventHandler({
        id, name, value, event: change, pageType, values, setFields, setBodyFields, setFieldValue,
      });
    }
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option.label || '',
  });

  const getCurrentValue = () => {
    if (typeof values[id] === 'object') {
      return values[id];
    }

    const value = sortedOptions.find((e) => e.id === values[name])
      || sortedOptions.find((e) => e.id === values[id]);

    return value || null;
  };

  const configSelect = {
    id,
    name: id,
    label,
    value: getCurrentValue(),
    options: sortedOptions,
    disableClearable: true,
    onChange: handleChange,
    filterOptions,
  };

  return (
    <Autocomplete
      {...configSelect}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => <TextField {...params} name={id} label={label} />}
    />
  );
};

export default DynamicSelect;
