const sortOptions = (optionsObj, sortArr) => {
  const options = [];

  if (sortArr) {
    sortArr.forEach((index) => {
      options.push({
        id: index,
        label: optionsObj[index],
      });
    });
  } else {
    Object.entries(optionsObj).forEach(([id, label]) => {
      options.push({ id, label });
    });
  }

  return options;
};

export default sortOptions;
