import {
  Box, FormHelperText, Grid, InputAdornment, TextField,
} from '@mui/material';
import { useFormikContext, useField } from 'formik';

import NumberField from '@/components/common/Form/NumberField';

const SingleInput = ({
  id,
  name,
  label,
  suffix,
  placeholder,
}) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const handleChange = (evt) => {
    const { value } = evt.target;
    setFieldValue(name, value);
  };

  const configInput = {
    ...field,
    label,
    id,
    error: !!meta?.error,
    value: meta.value || null,
    onChange: handleChange,
    placeholder,
    InputProps: {
      inputProps: {
        autoComplete: 'off',
      },
      inputComponent: NumberField,
      endAdornment: (
        <InputAdornment position="end">
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: suffix }}
          />
        </InputAdornment>
      ),
    },
  };

  return (
    <TextField {...configInput} />
  );
};

const CustomRange = (props) => {
  const { name, label, suffix } = props;
  const { errors } = useFormikContext();

  return (
    <Grid item xs={12} md={6} lg={4}>
      <Grid container alignItems="center">
        <Grid item xs>
          <SingleInput
            id={name.min}
            name={name.min}
            label={label}
            suffix={suffix}
            placeholder="-tól"
          />
        </Grid>
        <Box sx={{ mx: 2 }}>-</Box>
        <Grid item xs>
          <SingleInput
            id={name.max}
            name={name.max}
            label={label}
            suffix={suffix}
            placeholder="-ig"
          />
        </Grid>
      </Grid>
      <FormHelperText error>{errors[name.min] || errors[name.max]}</FormHelperText>
    </Grid>
  );
};
export default CustomRange;
