import { Grid, TextField, MenuItem } from '@mui/material';
import { useFormikContext } from 'formik';

const NativeRange = ({
  id,
  name,
  label,
  suffix,
  limit,
  disabled,
}) => {
  const { setFieldValue, values } = useFormikContext();

  const handleChange = (evt) => {
    const { value } = evt.target;
    setFieldValue(name, value);
  };

  const options = [];
  for (let i = limit.min; i * limit.step <= limit.max; i += 1) {
    options.push({
      id: `${name}-${i}`,
      label: `+${i * limit.step} ${suffix}`,
      value: i * limit.step,
    });
  }

  const configSelect = {
    id,
    name,
    select: true,
    label,
    disabled,
    variant: 'outlined',
    value: values[name] || '',
    onChange: handleChange,
  };

  return (
    <Grid item xs={12} md={6} lg={4}>
      <TextField {...configSelect}>
        {options.map((item) => (
          <MenuItem key={item.id} value={item.value.toString()}>{item.label}</MenuItem>
        ))}
      </TextField>
    </Grid>
  );
};

export default NativeRange;
