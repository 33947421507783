import { useEffect } from 'react';

import {
  Autocomplete, createFilterOptions, Grid, TextField,
} from '@mui/material';
import { useFormikContext } from 'formik';

import eventHandler from '../utils/eventHandler';

const LocationSelect = ({
  id, name, label, options, after, afterload, pageType, setFields,
}) => {
  const { setFieldValue, values } = useFormikContext();

  const handleChange = async (_, evt) => {
    let newValues = evt?.values;
    if (!evt) {
      newValues = {
        abj: null,
        job_country: null,
        w: '0',
      };
    }

    Object.entries(newValues).forEach(([key, val]) => {
      setFieldValue(key, val);
    });

    if (after) {
      await eventHandler({
        name,
        event: after,
        pageType,
        values: Object.assign(values, newValues),
        setFields,
        setFieldValue,
      });
    }
  };

  const handleInputChange = (evt) => {
    const { value } = evt.target;
    setFieldValue(name, value);
  };

  useEffect(() => {
    if (afterload && !options) {
      eventHandler({
        name, event: afterload, pageType, values, setFields, setFieldValue,
      });
    }

    if (values.w) {
      eventHandler({
        name, event: after, pageType, values, setFields, setFieldValue,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [afterload]);

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option.label || '',
  });

  const configSelect = {
    id,
    name,
    label,
    options: options || [],
    filterOptions,
    onChange: handleChange,
    onInputChange: handleInputChange,
  };

  return (
    <Grid item xs={12} md={6} lg={4}>
      <Autocomplete
        {...configSelect}
        getOptionLabel={(option) => option.label}
        groupBy={(option) => option.group}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        renderInput={(params) => <TextField {...params} label={label} />}
      />
    </Grid>
  );
};

export default LocationSelect;
