const categoryKeysInReverseOrder = (values) => Object.keys(values)
  .filter((cat) => cat.startsWith('cg'))
  .sort()
  .reverse();

const getCategory = (categoryConfigs) => {
  let foundCategoryId;

  categoryKeysInReverseOrder(categoryConfigs)
    .forEach((categoryKey) => {
      if (foundCategoryId && foundCategoryId !== '0') {
        return;
      }

      const categoryConfig = categoryConfigs[categoryKey];
      if (typeof categoryConfig === 'object') {
        foundCategoryId = categoryConfig.id;
        return;
      }

      foundCategoryId = categoryConfig;
    });

  return foundCategoryId;
};

export default getCategory;
