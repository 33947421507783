import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { useFormikContext } from 'formik';

const NativeCheckbox = ({
  id, name, options, classes,
}) => {
  const { setFieldValue } = useFormikContext();

  const handleChange = (evt) => {
    const { checked } = evt.target;
    setFieldValue(name, checked ? options.onValue : options.offValue);
  };

  const configCheckbox = {
    id,
    name,
    onChange: handleChange,
    value: options.onValue,
    type: 'checkbox',
  };

  // if there will be more classes in the future
  // please make a different component instead of this conditional styling
  return (
    <Grid item xs={12} md={6} lg={4}>
      <FormControlLabel
        control={<Checkbox {...configCheckbox} />}
        sx={
          classes && classes[0] === 'webseta' && {
            backgroundImage: 'url("/assets/searchBox/ingatlanseta_logo.png")',
            backgroundPosition: '52px',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            width: '186px',
            height: '38px',
          }
        }
        label={classes && classes[0] === 'webseta' ? '' : options.onTitle}
        title={classes && classes[0] === 'webseta' ? options.onTitle : ''}
      />
    </Grid>
  );
};

export default NativeCheckbox;
