import CustomRange from '@/components/landing/SearchBox/Fields/CustomRange';
import CustomSelect from '@/components/landing/SearchBox/Fields/CustomSelect';
import DisabledSelect from '@/components/landing/SearchBox/Fields/DisabledSelect';
import LocationSelect from '@/components/landing/SearchBox/Fields/LocationSelect';
import NativeCheckbox from '@/components/landing/SearchBox/Fields/NativeCheckbox';
import NativeHidden from '@/components/landing/SearchBox/Fields/NativeHidden';
import NativeRange from '@/components/landing/SearchBox/Fields/NativeRange';
import NativeText from '@/components/landing/SearchBox/Fields/NativeText';

const renderField = (field) => {
  const params = field;

  let id;
  if (typeof params.name === 'object') {
    id = Object.values(params.name).join('-');
  } else {
    id = `${field.name}${field?.priority || ''}`;
  }
  params.id = id;

  switch (params.type) {
    case 'nativeHidden':
      return <NativeHidden key={params.id} {...params} />;
    case 'nativeCheckbox':
      return <NativeCheckbox key={params.id} {...params} />;
    case 'nativeRange':
      return <NativeRange key={params.id} {...params} />;
    case 'nativeText':
      return <NativeText key={params.id} {...params} />;
    case 'customSelect':
      return <CustomSelect key={params.id} {...params} />;
    case 'customRange':
      return <CustomRange key={params.id} {...params} />;
    case 'locationSelect':
      return <LocationSelect key={params.id} {...params} />;
    case 'disabledSelect':
      return <DisabledSelect key={params.id} {...params} />;
    default:
      return null;
  }
};

export default renderField;
