import { useEffect, useState } from 'react';

import {
  Autocomplete, Grid, TextField,
} from '@mui/material';
import { useFormikContext } from 'formik';

import getSearchResults from '@/data/client/suggestions';

const NativeText = ({
  id, name, label, placeholderLabel,
}) => {
  const { setFieldValue, values } = useFormikContext();

  const [options, setOptions] = useState([]);

  const handleChange = (evt) => {
    const { textContent } = evt.target;
    setFieldValue(name, textContent);
  };

  const handleInputChange = (evt) => {
    const { value } = evt.target;
    setFieldValue(name, value);
  };

  useEffect(() => {
    if (values[name]) {
      getSearchResults(values[name], values.cg1)
        .then((results) => {
          setOptions(results);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values[name]]);

  const configText = {
    id,
    name,
    freeSolo: true,
    onChange: handleChange,
    onInputChange: handleInputChange,
  };

  return (
    <Grid item xs={12}>
      <Autocomplete
        {...configText}
        options={options}
        getOptionLabel={(option) => option.text}
        isOptionEqualToValue={(option, value) => option.text === value.text}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            value={values[name]}
            placeholder={placeholderLabel}
            inputProps={{
              ...params.inputProps,
              onKeyDown: (e) => {
                if (e.key === 'Enter') {
                  e.stopPropagation();
                }
              },
            }}
          />
        )}
      />
    </Grid>
  );
};

export default NativeText;
