import { useFormikContext } from 'formik';

const NativeHidden = ({ id, name }) => {
  const { values } = useFormikContext();

  const configHidden = {
    id,
    name: id,
    defaultValue: values[name],
    type: 'hidden',
  };

  return (
    <input {...configHidden} />
  );
};

export default NativeHidden;
