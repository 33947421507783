import {
  Box, Divider, Tab, Tabs,
} from '@mui/material';
import { useFormikContext } from 'formik';

import eventHandler from '../utils/eventHandler';
import sortOptions from '../utils/sortOptions';

const TabSelect = (props) => {
  const {
    name, change, setFields, setBodyFields, pageType, options, sort,
  } = props;

  const sortedOptions = sortOptions(options, sort).filter((o) => o.label);
  const { setFieldValue, values } = useFormikContext();

  const handleTabChange = async (_, value) => {
    setFieldValue(name, value);

    await eventHandler({
      name, value, event: change, pageType, setFields, setBodyFields,
    });
  };

  const defaultTab = () => {
    if (values[name] in options) {
      return values[name];
    }
    if (options[0] === 'Összes') {
      return Object.keys(options)[1];
    }
    return Object.keys(options)[0];
  };

  const configTabs = {
    value: defaultTab(),
    textColor: 'secondary',
    indicatorColor: 'secondary',
    onChange: handleTabChange,
    variant: 'fullWidth',
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
      }}
    >
      <Tabs {...configTabs}>
        {sortedOptions.map(({ id, label }) => (
          <Tab
            label={label}
            id={`search-taxb-${id}`}
            value={id}
            key={`search-tab-key-${id}`}
            sx={{ minWidth: '75px' }}
          />
        ))}
      </Tabs>
      <Divider />
    </Box>
  );
};

export default TabSelect;
