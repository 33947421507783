import React from 'react';

import { NumericFormat } from 'react-number-format';

const NumberField = React.forwardRef(({ onChange, ...props }, ref) => (
  <NumericFormat
    {...props}
    getInputRef={ref}
    onValueChange={(values) => {
      onChange({
        target: {
          name: props.name,
          value: values.value,
        },
      });
    }}
    allowNegative={false}
    thousandSeparator={' '}
    valueIsNumericString
  />
));

export default NumberField;
