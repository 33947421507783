import { useEffect } from 'react';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Autocomplete, Checkbox, createFilterOptions, TextField, Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';

import eventHandler from '../utils/eventHandler';
import sortOptions from '../utils/sortOptions';

const MultiSelect = ({
  id, name, label, pageType, options, sort, sortMethod, after, afterload, setFields,
}) => {
  const { setFieldValue, values } = useFormikContext();
  const sortedOptions = sortOptions(options, sort);

  useEffect(() => {
    if (afterload && values[name]) {
      eventHandler({
        name, event: afterload, pageType, values, setFields, setFieldValue,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [afterload]);

  const handleChange = (_, value) => {
    const newValue = value.map((e) => (e?.id !== undefined ? e.id : e));
    setFieldValue(name, newValue);

    if (after) {
      eventHandler({
        name, value: newValue, event: after, pageType, values, setFields, setFieldValue,
      });
    }
  };

  if (sortMethod && sortMethod.asc) {
    sortedOptions.sort((a, b) => a.label.localeCompare(b.label));
  }

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option.label || '',
  });

  const configSelect = {
    id,
    multiple: true,
    disableClearable: true,
    disableCloseOnSelect: true,
    value: values[name] || [],
    options: sortedOptions,
    onChange: handleChange,
    filterOptions,
  };

  return (
    <Autocomplete
      {...configSelect}
      renderTags={(value) => {
        const tags = value.map((v) => options[v]).join(', ');
        return <Typography noWrap sx={{ pl: 1, maxWidth: '80%' }}>{tags}</Typography>;
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.label}
        </li>
      )}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.id === value}
      renderInput={(params) => <TextField {...params} name={name} label={label} />}
    />
  );
};

export default MultiSelect;
