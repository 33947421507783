import { Grid } from '@mui/material';

import DoubleSelect from './DoubleSelect';
import DynamicSelect from './DynamicSelect';
import MultiSelect from './MultiSelect';
import TabSelect from './TabSelect';

const CustomSelect = (props) => {
  const { mode, double, tab } = props;
  if (mode === 'multi') {
    return (
      <Grid item xs={12} md={6} lg={4}>
        <MultiSelect {...props} />
      </Grid>
    );
  }

  if (double) {
    return (
      <Grid item xs={12} md={6} lg={4}>
        <DoubleSelect {...props} />
      </Grid>
    );
  }

  if (tab) {
    return (
      <TabSelect {...props} />
    );
  }

  return (
    <Grid item xs={12} md={6} lg={4}>
      <DynamicSelect {...props} />
    </Grid>
  );
};

export default CustomSelect;
