import { Grid, MenuItem, TextField } from '@mui/material';

const DisabledSelect = ({ id, name, label }) => {
  const configSelect = {
    id,
    name,
    label,
    value: '',
    fullWidth: true,
    select: true,
    disabled: true,
  };

  return (
    <Grid item xs={12} md={6} lg={4}>
      <TextField {...configSelect}>
        <MenuItem value="">-</MenuItem>
      </TextField>
    </Grid>
  );
};

export default DisabledSelect;
