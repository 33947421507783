import getCategoryFromValues from './getCategory';
import { apiUrl, apiKey } from '@/config';
import measurableFetch from '@/helpers/measurableFetch';

const submitHandler = async (params, fields, router) => {
  let values = {};

  fields.forEach(({ name, disabledValues, multiplier = false }) => {
    if (name instanceof Object) {
      Object.values(name).forEach((n) => {
        values[n] = params[n]?.id ? params[n].id : params[n];
        if (multiplier && values[n] && values[n] !== '') {
          values[n] *= multiplier;
        }
      });
    } else {
      // Filter out disabled values
      const param = params[name]?.id ? params[name].id : params[name];
      if (!disabledValues || !disabledValues.includes(param)) {
        values[name] = param;
        if (multiplier && values[name] && values[name] !== '') {
          values[name] *= multiplier;
        }
      }
    }
  });

  // Remove empty values
  // eslint-disable-next-line no-unused-vars
  values = Object.fromEntries(Object.entries(values).filter(([key, value]) => value !== undefined && value !== '' && key !== 'location'));

  values.cg = getCategoryFromValues(params);
  values.w = values.w > 0 ? +values.w + 100 : 3;

  const url = new URL(`${apiUrl}/v2/config/friendlyURL?api_key=${apiKey}`);
  Object.keys(values).forEach((key) => url.searchParams.append(key, values[key]));

  const res = await measurableFetch(url, {
    method: 'GET',
  });

  if (res.status === 200) {
    const data = await res.json();
    if (data.friendly_url) {
      router.push(data.friendly_url);
    }
  }
};

export default submitHandler;
