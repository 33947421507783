import {
  Autocomplete, Box, createFilterOptions, FormHelperText, Stack, TextField,
} from '@mui/material';
import { useField, useFormikContext } from 'formik';

import sortOptions from '../utils/sortOptions';

const SingleSelect = ({
  name, label, options, sort, defaultValue,
}) => {
  const [meta] = useField(name);
  const { setFieldValue, values } = useFormikContext();
  const sortedOptions = sortOptions(options, sort);

  const handleChange = (_, value) => {
    setFieldValue(name, value ? +value.id : undefined);
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option.label || '',
  });

  const getCurrentValue = () => {
    const value = sortedOptions.find((e) => +e.id === values[name])
      || sortedOptions.find((e) => +e.id === values[name]);

    return value || null;
  };

  const configSelect = {
    name,
    label,
    fullWidth: true,
    options: sortedOptions,
    value: getCurrentValue(),
    onChange: handleChange,
    filterOptions,
  };

  return (
    <Autocomplete
      {...configSelect}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => +option.id === +value.id}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={defaultValue}
          error={!!meta?.error}
        />
      )}
    />
  );
};

const DoubleSelect = (props) => {
  const {
    name, label, options, sort, notes,
  } = props;
  const { errors } = useFormikContext();

  return (
    <>
      <Stack direction="row" alignItems="center">
        <SingleSelect
          label={label}
          name={Object.values(name)[0]}
          options={options[Object.keys(name)[0]]}
          defaultValue={notes.select[Object.keys(name)[0]]}
          sort={sort}
        />
        <Box sx={{ mx: 2 }}>-</Box>
        <SingleSelect
          label={label}
          name={Object.values(name)[1]}
          options={options[Object.keys(name)[1]]}
          defaultValue={notes.select[Object.keys(name)[1]]}
          sort={sort}
        />
      </Stack>
      <FormHelperText error>{errors[name.min] || errors[name.max]}</FormHelperText>
    </>
  );
};

export default DoubleSelect;
