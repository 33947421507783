import { searchParamsUrl } from '@/config';
import measurableFetch from '@/helpers/measurableFetch';

const fetchSearchParams = async ({ query, path = '/params' }) => {
  const url = new URL(`${searchParamsUrl}${path}`);
  Object.keys(query).forEach((key) => url.searchParams.append(key, query[key]));

  return measurableFetch(url, {
    method: 'GET',
  })
    .then((res) => res.json());
};

export default fetchSearchParams;
