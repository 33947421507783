import { baseUrl, searchSuggesterUrl } from '@/config';
import measurableFetch from '@/helpers/measurableFetch';

const getSearchResults = (term, category = '') => measurableFetch(`${searchSuggesterUrl}?query=${term}&category=${category}`)
  .then((res) => res.json())
  .then((res) => res.suggestions?.map(
    (suggestion) => ({
      text: suggestion.value,
      href: `${baseUrl}/magyarorszag?q=${suggestion.value}`,
    }),
  ))
  .catch(() => []);

export default getSearchResults;
