import { produce } from 'immer';

import getCategoryFromValues from './getCategory';
import fetchSearchParams from '@/data/client/searchParams';

const checkForClearValue = (data, values, field) => {
  if (!data.options) return true;

  const value = values[field]?.id || values[field];
  // For MultiSelect fields
  if (typeof value === 'object' && !value.every((i) => Object.keys(data.options).includes(`${i}`))) {
    return true;
  }

  // For Single select fields
  if (typeof value === 'string' && !Object.values(data.options).includes(`${value}`)) {
    return true;
  }

  return false;
};

const eventHandler = async ({
  id, name, value, event, pageType, values, setFields, setBodyFields, setFieldValue,
}) => {
  const query = { area: 'head,body', type: pageType, view_type: 'landing' };

  event.fields.forEach((element) => {
    if (Array.isArray(value) && name === element) {
      query[element] = value;
    } else if (value && name === element) {
      query[element] = (value?.id !== undefined) ? value.id : value;
    } else {
      query[element] = values[element]?.id || values[element];
    }
  });

  if (Object.prototype.hasOwnProperty.call(query, 'cg')) {
    query.cg = name === 'cg' ? (value?.id || value) : getCategoryFromValues({ ...values, id: value });
  }

  const res = await fetchSearchParams({
    path: event.endpoint,
    query,
  });

  if (res.status !== 'OK') {
    return;
  }

  if (res.method === 'destroy') {
    setFields(produce((draft) => {
      res.params.forEach((param) => {
        const exists = draft.findIndex((f) => f.name === param);
        if (exists > -1) {
          draft.splice(exists, 1);
          setFieldValue(param, '');
        }
      });
    }));
  }

  if (res.method === 'replaceFields') {
    setFields(produce((draft) => {
      res.params.forEach(({ field, data }) => {
        const exists = draft.findIndex((f) => f.name === field);
        if (exists > 0) {
          draft.splice(exists, 1, data);
          if (checkForClearValue(data, values, field)) {
            setFieldValue(field, '');
          }
        }
      });
    }));
  }

  if (res.method === 'replaceField') {
    setFields(produce((draft) => {
      let exists = -1;
      draft.forEach((f, index) => {
        if (f.name instanceof Object) {
          Object.values(f.name).forEach((fieldKey) => {
            if (fieldKey === res.field) {
              exists = index;
            }
          });
        } else if (f.name === res.data.field) {
          exists = index;
        }
      });
      if (exists > 0) {
        draft.splice(exists, 1, res.data);
      }
    }));
  }

  if (res.method === 'destroyAll') {
    setFields(produce(() => res.head));
    if (typeof res.body !== 'undefined') {
      setBodyFields(produce(() => res.body));
    }

    if (id === 'cg2') {
      // We need to reset these fields manually by selecting category
      setFieldValue('cg3', '');
      setFieldValue('cb_cars', '');
      setFieldValue('mo_cars', '');
    }
  }
};

export default eventHandler;
