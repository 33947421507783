import {
  Button, Paper, Stack, Typography,
} from '@mui/material';

const SearchHistory = ({ searchHistory = {} }) => (
  <Paper elevation={0} sx={{ backgroundColor: 'rgba(0, 0, 0, 0.04)', p: 2 }}>
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      justifyContent="space-between"
      spacing={4}
    >
      <Stack direction="column">
        <Typography variant="h4">Legutóbbi keresésed:</Typography>
        <Typography variant="caption">{searchHistory.label}</Typography>
      </Stack>
      <Button
        color="secondary"
        variant="text"
        href={searchHistory.link}
        sx={{ whiteSpace: 'nowrap' }}
      >
        Újra megnézem
      </Button>
    </Stack>
  </Paper>
);

export default SearchHistory;
